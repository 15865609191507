import * as React from 'react'
import Layout from '../components/index'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/homepage.scss'
import { navigate } from 'gatsby'

const SouthernTouchScreen = () => {
  return (

      <Layout>
        <div className="wrapper">
          
          <div className="button-box-wrapper" style={{animation: "bounce ease-in-out 6s infinite"}}> 
            <button className="nextBtn button-box" onClick={()=>{navigate("/home")}}>
              <StaticImage 
                className="img-filter-white"
                alt="Southern 360"
                src="../images/arrow-right.png"
              />
              Southern 360
            </button>
          </div>
          <div className="button-box-wrapper" style={{animation: "bounce2 ease-in-out 6s infinite"}}>
            <button className="nextBtn button-box" onClick={()=>{navigate("https://southernco.app/")}}>
              <StaticImage 
                className="img-filter-white"
                alt="Southern"
                src="../images/arrow-right.png"
              />
              Southern
            </button>
          </div>
        </div>
      </Layout>
  )
}

export default SouthernTouchScreen