import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/style.scss'
import { Link } from "gatsby"
import Header from './header.js'

class Layout extends React.Component{

  constructor(props){

    super(props);

    this.state = {
      pathname: null,
    }
  }

  componentDidMount(){

    let home_page_btn = document.querySelector(".home-page-btn");

    if(window.location.pathname === "/"){
      home_page_btn.style.display = "none";
    }else{
      home_page_btn.style.display = "block"; 
    }

  }
  // <StaticImage
  //   alt="southern company logo"
  //   src="../images/logo.svg"
  // />

  render() {
      return(
        <div className="page">
          <title>{this.props.pageTitle}</title>
          <Header uniqueName="intro"/>
          <main className="homepage">
            { this.props.children }
          </main>
          <footer>
            <div className="footer-left">
            </div>
            <div className="footer-middle">
              {this.props.nextBottomSlideBtn && 
                <Link className="next-bottom-slide-btn" to={this.props.nextBottomSlideBtnLink} > 
                  <StaticImage
                    alt="down-arrow"
                    src="../images/arrow-down.png"
                  />
                  <span>{this.props.nextBottomSlideBtn}</span>
                </Link>
              }
            </div>
            <div className="footer-right">

              <Link className="home-page-btn" to="/">
                <StaticImage
                  alt="home link"
                  src="../images/home-btn.svg"
                />  
              </Link>

            </div>
          </footer>
        </div>
    )}

} 


export default Layout